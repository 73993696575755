<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="primary"
      v-if="$admin.can('inventory-update')"
      @click="ExportAllHubs()"
      :loading="is_uploading"
    >
      <v-icon
        small
        class="mr-2"
      >
        mdi-download
      </v-icon>
      {{ this.$t("ExportAllHubs") }}
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="inventories"
      :item-class="itemBG"
      :loading="isLoadingInventories"
      :server-items-length="inventoriesMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.validate="{ item }">
        <v-btn
          v-if="$admin.can('inventory-update-status') && item.status == 'open'"
          x-small
          color="green"
          :loading="is_loading && index == item.id"
          :disabled="is_loading && index == item.id"
          @click.stop="validateInvetory(item)"
        >
          <v-icon size="16"> mdi-check-bold </v-icon>&nbsp;{{ $t("validate") }}
        </v-btn>
        <v-menu
          offset-y
          v-if="item.status == 'closed'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="16"> mdi-cloud-download </v-icon>&nbsp;
              {{ $t("export") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              dense
              @click="() => printPDF(item)"
            >
              <v-list-item-title>
                <v-icon size="16"> mdi-file-pdf-box </v-icon>&nbsp;PDF
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              dense
              @click="() => exportCSV(item)"
            >
              <v-list-item-title>
                <v-icon size="16"> mdi-download </v-icon>&nbsp;CSV
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.actions="{ item }">
        <template v-if="item.status == 'open' || item.status == 'pending'">
          <import-CSV
            :InventoryData="item"
            :Users="listUsers"
            v-if="$admin.can('inventory-create')"
          ></import-CSV>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  v-if="$admin.can('inventory-update')"
                  small
                  class="mr-2"
                  @click="() => exportBATCHCSV(item)"
                >
                  mdi-download
                </v-icon>
              </span>
            </template>
            <span>{{ $t("export_inventory_batch_csv") }}</span>
          </v-tooltip>
          <v-icon
            v-if="$admin.can('inventory-view')"
            small
            class="mr-2"
            @click="viewItem(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-if="$admin.can('inventory-update')"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="$admin.can('inventory-delete')"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-else>
          <v-icon
            v-if="$admin.can('inventory-view')"
            small
            class="mr-2"
            @click="viewItem(item)"
          >
            mdi-eye
          </v-icon>
        </template>
      </template>

      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("inventories") }}</v-toolbar-title>
          <v-divider
            class="mx-2"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <delete-inventories-dialog
            v-if="$admin.can('inventory-delete')"
          ></delete-inventories-dialog>
        </v-toolbar>
        <v-row>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              clearable
              v-model="options.type"
              :items="invTypesList"
              item-value="id"
              item-text="name"
              :label="$t('inventory_types')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              clearable
              v-model="options.inventory_status"
              :items="inventoryStatuses"
              :label="$t('status')"
            ></v-select>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [helpers],

  components: {
    DeleteInventoriesDialog: () =>
      import("../dialogs/DeleteInventoriesDialog.vue"),
    ImportCSV: () => import("../dialogs/ImportCSVBatch.vue"),
  },

  async mounted() {},

  computed: {
    ...mapGetters({
      isLoadingInventories: "inventories/isLoadingInventories",
      inventories: "inventories/listInventories",
      inventoriesMeta: "inventories/meta",
      hubs: "hubs/activeHubs",
      listUsers: "users/listUsers",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        this.is_loading_inventory = true;

        await this.$store.dispatch("inventories/list", params).then(() => {
          this.is_loading_inventory = false;
          if (params.page > this.inventoriesMeta.lastPage) {
            params.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "inbound",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      is_loading_inventory: false,
      is_loading_csv: false,
      options: {},
      index: 0,
      is_uploading: false,
      is_loading_invoice: false,
      is_loading_dn: false,
      is_loading: false,
      invTypesList: [
        { id: "global", name: "Inventaire Globale" },
        { id: "periodic", name: "Inventaire Tournant" },
      ],
      inventoryStatuses: ["open", "history"],

      headers: [
        {
          text: "ID",
          align: "start",
          value: "tracking_number",
        },
        {
          text: this.$t("inventory_type"),
          align: "center",
          value: "type",
          width: "15%",
        },
        {
          text: this.$t("status"),
          align: "center",
          value: "status",
          width: "15%",
        },

        {
          text: this.$t("created_by"),
          value: "inventory_creator",
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
        },
        {
          text: this.$t("validated_at"),
          value: "validated_at",
        },
        {
          text: this.$t("validate"),
          value: "validate",
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },

  methods: {
    async validateInvetory(item) {
      this.index = item.id;
      this.is_loading = true;
      const that = this;
      this.$swal({
        icon: "question",
        text: "Validating inventory, Are you sure?",
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try {
            await that.$store.dispatch("inventories/confirm", item);
            that.$store.dispatch(
              "alerts/success",
              that.$t("updated_successfully")
            );
          } catch (error) {
            that.$store.dispatch("alerts/error", error.response.data.message);
          }
        }
      });
      this.is_loading = false;
    },

    async editItem(item) {
      this.$router.push({
        name: "inventories.edit",
        params: { id: item.id },
      });
    },
    async viewItem(item) {
      this.$router.push({
        name: "inventories.view",
        params: { id: item.id },
      });
    },

    deleteItem(item) {
      this.$store.dispatch("inventories/openDeleteDialog", item);
    },

    itemBG(item) {
      if (item?.status == "open") return "red lighten-5";
      return "";
    },
    async printPDF(item) {
      await this.$store
        .dispatch("inventories/exportPDF", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventory_" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
    async exportCSV(item) {
      await this.$store
        .dispatch("inventories/exportCSV", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventory_" + item.tracking_number + ".csv"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
    async ExportAllHubs() {
      this.is_uploading = true;
      await this.$store
        .dispatch("inventories/exportAllHubsCSV")
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "inventory_AllHubs.csv");
          document.body.appendChild(link);
          link.click();
          this.is_uploading = false;
        })
        .catch((error) => {
          this.is_uploading = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },

    async exportBATCHCSV(item) {
      console.log("Export CSV ", item);
      await this.$store
        .dispatch("inventories/exportBATCHCSV", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventory_BATCH_" + item.tracking_number + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
    async exportQuantityCSV(item) {
      console.log("Export CSV ", item);
      await this.$store
        .dispatch("inventories/exportQuantityCSV", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventory_Quantity_" + item.tracking_number + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
  },
};
</script>
